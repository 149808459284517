import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ImgPudu from '../../assets/images/pudu.jpg'
function Proyecto(){
  return(
    <>
      <ElProyecto/>
      <Caracteristicas />
    </>
  )
}
export default Proyecto

function ElProyecto(){
  return(
    <div id="reserva" className="section pb-4">
      <Container>
        <Row className="justify-content-center">
          <h2 className="title">El Proyecto</h2>
        </Row>
        <Row className="pt-3">
          <Col className="text-sm-center text-md-left" sm="12" md="4">
            <img  style={{maxWidth:'420px', width:'320px'}} src={ImgPudu} alt="img-pudu"/>
          </Col>
          <Col md="8">
            <div className="pl-sm-3 pl-md-1">
              <h3 className="title mt-0 pt-sm-4">Reserva Pudú</h3>
              <p className="description">
                Reserva Pudu Etapa 2,  está orientada a personas amantes de la naturaleza. 
                Con un maravilloso entorno rodeado de bosques nativos.
                El nombre “ Reserva Pudú” se debe a la gran cantidad de pudúes que existen en la zona y 
                que habitan en el interior de la Reserva.   
                Los propietarios de las parcelas tendrán acceso al camino interior comunitario de la reserva, 
                con la finalidad de hacer trekking, fotografía natural y obtener una gran experiencia, 
                descubriendo el hermoso entorno natural de Chiloé.
              </p>

            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
function Caracteristicas(){
  return(
    <div id="caracteristicas">
      <div
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/caracteristicas.jpg") +
              ")",
          }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center pt-0">
                  Características
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-lightning" />
                  </div>
                  <h4 className="title">Factibilidad de energía eléctrica en acceso al predio.</h4>
                  <h4 className="title">Factibilidad de agua a través de pozo en cada parcela.</h4>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-smart-house" />
                  </div>
                  <h4 className="title">Excelente conexión de telefonía e internet.</h4>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-i-lock" />
                  </div>
                  <h4 className="title">Portón de acceso.</h4> 
                  <h4 className="title">Todas las parcelas tienen su rol propio del SII</h4>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-position-marker" />
                  </div>
                  <h4 className="title">A 3 horas de Santiago.(en avión de Santiago a Castro.)

                  A solo 4,5 km de la Ruta 5.

                    A 1 km de Yatehue (playa, colegio, supermercado, etc..)</h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </div>
  )
}