import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { PhotoSwipeGallery } from 'react-photoswipe'
let options = {
    //http://photoswipe.com/documentation/options.html
}
  
let getThumbnailContent = item => {
    return (
        <div
            className="gallery-item"
            itemProp="associatedMedia"
            itemScope=""
            itemType="http://schema.org/ImageObject"
        >
            <a data-size="750x1002" href={item.src} itemProp="contentUrl">
            <img
                alt="..."
                className="img-rounded img-responsive"
                itemProp="thumbnail"
                src={item.src}
            />
            </a>
            <figcaption className="gallery-caption" itemProp="caption description">
            {item.title}
            </figcaption>
      </div>
    )
}
const photoSwipeItems = [
    {
      src: require("assets/images/galeria/1.jpg"),
      title: "",
      w: "1280",
      h: "883"
    },
    {
      src: require("assets/images/galeria/2.jpg"),
      title: "",
      w: "1280",
      h: "883"
    },
    {
      src: require("assets/images/galeria/3.jpg"),
      title: "",
      w: "1280",
      h: "883"
    },
    {
      src: require("assets/images/galeria/4.jpg"),
      title: "",
      w: "1280",
      h: "883"
    },
    {
      src: require("assets/images/galeria/5.jpg"),
      title: "",
      w: "1280",
      h: "883"
    },
    {
      src: require("assets/images/galeria/6.jpg"),
      title: "",
      w: "1280",
      h: "883"
    },
    {
      src: require("assets/images/galeria/7.jpg"),
      title: "",
      w: "1280",
      h: "883"
    },
    // {
    //   src: require("assets/images/galeria/galeria8.jpg"),
    //   title: "",
    //   w: "1280",
    //   h: "720"
    // },
    {
        src: require("assets/images/galeria/galeria9.jpg"),
        title: "",
        w: "1280",
        h: "720"
    },
    {
        src: require("assets/images/galeria/galeria10.jpg"),
        title: "",
        w: "1280",
        h: "720"
    },
  
  ];
const Galeria = () => {
    return(
        <div className="section text-center" id="galeria">
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto text-center" md="8">
                        <h2 className="title">Galeria</h2>
                    </Col>
                </Row>
                
                    <PhotoSwipeGallery
                        items={photoSwipeItems}
                        options={options}
                        thumbnailContent={getThumbnailContent}
                    />
                
            </Container>
        </div>
    )
}
export default Galeria